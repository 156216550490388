import { Suspense } from "react";
import { lazyRetry } from "src/utility/lazyRetry";

const ClientToolsImage = lazyRetry(
  () => import("src/pages/clientConnectivity/ClientToolsImage"),
);

export function ClientToolsImageLoader(props: {
  imageIcon: string;
  className?: string;
}) {
  return (
    <Suspense
      fallback={
        <i className="fa-regular fa-image client-connectivity-icon-fallback"></i>
      }
    >
      <ClientToolsImage {...props} />
    </Suspense>
  );
}
